import React from "react";
import canUseDOM from 'can-use-dom';
import { graphql } from 'gatsby'
import SEO from "@/components/seo";
import Layout from "@/components/layout";
import GalleryNav from "@/components/GalleryNav";
import GalleryList from "@/components/GalleryList";
import GalleryListHeader from "@/components/GalleryListHeader";
import Breadcrumbs from "@/components/Breadcrumbs";

const PAGE = {
  pageTitle: 'KOJI OTANI | Gallery',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const GalleryListPage = ({ props }) => {
  const cities = props.data.cities.edges.map(v => v.node);
  const country = props.data.country;

  const breadcrumbs = [
    {
      title: country.region.name,
      path: `/gallery/${country.region.id}`
    },
    {
      title: country.name,
      path: `/gallery/${country.id}`
    },
  ];

  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <GalleryNav />
      <Breadcrumbs items={breadcrumbs} />
      <GalleryListHeader
        title={country.name}
      />
      <GalleryList items={cities} />
    </Layout>
  );
};

const GalleryListPageContainer = (props) => {
  if (
      props && props.data && props.data.country &&
      props.data.cities && props.data.cities &&
      canUseDOM
    ) {
      return (
        <GalleryListPage props={props} />
      );
    } else {
      return (
        <div>loading...</div>
      )
    };
};

export default GalleryListPageContainer;

export const query = graphql`
  query($id: String) {
    cities: allCitiesJson(filter: { country: { id: { eq: $id } } }) {
      edges {
        node {
          id
          name
          gallery
          region {
            id
            name
          }
          country {
            id
            name
          }
          map {
            lat
            lng
          }
        }
      }
    }
    country: countrysJson(id: { eq: $id } ) {
      id
      name
      cover
      region {
        id
        name
      }
    }
  }
`;